import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModel } from "../models";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class UserService {
  private authUrl = `${environment.authUrl}`;
  private assetUrl = `${environment.assetUrl}`;

  constructor(private router: Router, private http: HttpClient, private auth: AuthenticationService) {}

  createUser(user): Observable<any> {
    return this.http.post(`${this.authUrl}/user`, user)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getUsers(page: number, limit: number, filterObject): Observable<any> {
    let query = `?page=${page}&limit=${limit}`;
    if (filterObject.status != null) query = `${query}&status=${filterObject.status}`;
    if (filterObject.name != null) query = `${query}&name=${filterObject.name}`;

    return this.http.get(`${this.assetUrl}/auth/user/list${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.assetUrl}/auth/user`, {observe: 'response'}).pipe(catchError((error: any) => throwError(error)));
  }
  getUserFromGateWay(): Observable<any> {
    return this.http.get(`${this.authUrl}/user`).pipe(catchError((error: any) => throwError(error)));
  }
  getUserById(userId: number): Observable<any> {
    return this.http.get(`${this.assetUrl}/auth/user/${userId}`).pipe(catchError((error: any) => throwError(error)));
  }

  updateUser(user: UserModel): Observable<any> {  
    return this.http.patch(`${this.authUrl}/user`, user).pipe(catchError((error: any) => throwError(error)));
  }
  updateManager(user: UserModel, email?: string): Observable<any> {
    return this.http.patch(`${this.authUrl}/user/${email ? email : ''}`, user).pipe(catchError((error: any) => throwError(error)));
  }

  updateCompany(company): Observable<any>{
    return this.http.put(`${this.assetUrl}/asset/company`, company).pipe(catchError((error: any) => throwError(error)));
  }
  deleteCompanyLogo(): Observable<any>{
    return this.http.delete(`${this.assetUrl}/asset/company/logo`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getFullName(user) {
    if (user) {
      const fullname = `${user.first_name} ${user.last_name}`;
      return fullname;
    } else return "";
  }
  isManager(user) {
    if (user && user?.user_roles?.includes('manager') || user?.lissa_roles?.includes('manager')) {
      return true;
    } 
    return false;
  }

  isServiceManager(user) {
    const smRoles = ['service-admin', 'service-manager'];
  
    if (user) {
      return smRoles.some(role => 
        user.user_roles?.includes(role) || user.lissa_roles?.includes(role)
      );
    }
    return false;
  }
  
  isAllowedToAccess(user) {
    const allowedRoles = ['admin', 'service-admin', 'service-manager'];
  
    if (user) {
      return allowedRoles.some(role => 
        user.user_roles?.includes(role) || user.lissa_roles?.includes(role)
      );
    }
  
    return false;
  }
  
  get loggedUser(): UserModel {
    return JSON.parse(localStorage.getItem('current_user'))?.user;
  }

  set loggedUser(user: UserModel) {
    const updatedUser = { token: this.auth.getToken(), user };
    localStorage.setItem('current_user', JSON.stringify(updatedUser));
  }

  exportUserList(company_id: number, filterObject?): Observable<any> {
    let params = new HttpParams();
    
    params = params.set('company_id', company_id.toString())

    Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
            params = params.set(k, filterObject[k]);
        }
    });

    return this.http.get(`${this.assetUrl}/auth/user/export`, {params})
    .pipe(catchError((error: any) => throwError(error)));
  }
}
