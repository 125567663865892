<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>{{data?.title || ('MODALS.Are you sure' | translate)}}</h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <span>{{data?.message || ('MODALS.This will' | translate)}}</span>
    </div>
    <div class="buttons">
      <button mat-dialog-close class="btn btn-secondary">
        {{data?.cancelText || ('MODALS.CANCEL' | translate) | uppercase}}
      </button>
      <button (click)="confirm()" class="btn btn-primary" [ngClass]="{'btn-danger': data?.isDanger}">
        {{data?.confirmText || ('MODALS.Delete' | translate) | uppercase}}
      </button>
    </div>
  </div>
</div>
