<div class="device_activity">
    <h3 class="device-head mb-0">{{'DEVICEINFO.Device Activity' | translate}}</h3>
    
    <div class="card">
      <div class="card-block">
        <div class="timeline">
          <article class="timeline-entry" *ngFor="let deviceActivity of (showMore ? deviceActivities : deviceActivities.slice(0,5)); let i = index;">
            <!-- warning, success, primary, default  -->
            <div class="timeline-status">
              <img src="../../../../assets/icons/timeline_{{i == 0 ? iconPrefix + '_active' : 'inactive'}}.svg" alt="">
            </div>
            <div class="timeline-label border-bottom">
              <div class="row">
                <div class="col-md-8">
                  <time
                    class="text-muted ">{{deviceActivity?.created_at | dateFormat: 'DD.MM.YYYY'}} {{deviceActivity?.device_status_id !== 9 && deviceActivity?.user ? '/ ' + ('DEVICEINFO.by' | translate)  : ' '}} {{deviceActivity?.user | fullname}}</time>
                  <p class="bold-text">
                    {{deviceActivity?.title_translated && translate.currentLang == 'de' ? 
                      deviceActivity?.title_translated : 
                      (deviceActivity?.title | replaceText: deviceActivities)}} 
                    {{deviceActivity?.employee | fullname}}
                  </p>
                  <p>{{deviceActivity?.note}}</p>
                  <p class="comment">{{deviceActivity?.comment}}</p>
                  <p *ngIf="deviceActivity?.archive_report">Reason: {{deviceActivity?.archive_report?.reason}}</p>
                  <p *ngIf="deviceActivity?.archive_report?.note">Note: {{deviceActivity?.archive_report?.note}}</p>
                </div>

                <!-- Print Swap -->
                <div class="col-md-3 d-flex align-items-center"
                  *ngIf="deviceActivity?.device_status_id == 5 && deviceActivity?.swap_ticket != null">
                  <button class="btn btn-sm btn-outline-default"
                  [class.btn-disabled]="device | hasRobotAction"
                  (click)="swapTicket(currentUser.company_id,deviceActivity?.id)">Print Swap</button>
                </div>

                <!-- Print assigment -->
                <div class="col-md-4 d-flex align-items-center justify-content-end"
                  *ngIf="shouldShowPrintButton(deviceActivity, deviceActivities)">
                  <button class="btn btn-sm btn-outline-default d-flex align-items-center"
                  (click)="printAssigment(deviceActivity, device)">
                  <mat-icon class="mr-2 ">print</mat-icon>
                  {{'DEVICEINFO.Print Confirmation' | translate}}</button>
                </div>

                <!-- Download Certificate -->
                <div class="col-md-4 d-flex align-items-center justify-content-end"
                  *ngIf="deviceActivity?.activity_type === 35">
                  <a class="btn btn-sm btn-outline-default d-flex align-items-center"
                    [href]="device?.securaze_erasure_file_link" download
                    target="_blank">
                  <mat-icon class="mr-2 ">download</mat-icon>
                  {{'DEVICEINFO.Download certificate' | translate}}</a>
                </div>
              </div>
            </div>
          </article>
          <div class="text-center btn-show-more" *ngIf="deviceActivities.length > 5" (click)="showMore = !showMore; this.getDeviceActivities()">
            <p>{{(showMore ? 'DEVICEINFO.Show less' : 'DEVICEINFO.Show more')| translate | uppercase}}</p>
            <mat-icon class="material-icons-outlined">
                {{showMore && 'expand_less' || 'expand_more'}}
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
