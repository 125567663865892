import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DeviceActivitiesModel } from '../../models/device-activities';
import { DeviceService, PrintService, StyleConfiguratorService } from '../../services';
import { DeviceModel, UserModel } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-activity',
  templateUrl: './device-activity.component.html',
  styleUrls: ['./device-activity.component.scss']
})
export class DeviceActivityComponent implements OnInit {
  @Input() device: DeviceModel = new DeviceModel();
  @Input() currentUser: UserModel = new UserModel();
  deviceActivities: DeviceActivitiesModel[] = [];
  showMore: boolean = false;
  perPage: number = 5;
  currentPage: number = 1;
  currentLang;
  iconPrefix: string;
  deviceReturned: boolean;
  private refreshActivitiesSub: Subscription;
  latestAssignActivity: DeviceActivitiesModel;

  constructor(
    private deviceService: DeviceService,
    private printService: PrintService,
    public translate: TranslateService,
    private styleConf: StyleConfiguratorService
    ) { 
      setTimeout(() => { this.currentLang = translate.store.currentLang}, 1000);
      this.iconPrefix =  this.styleConf.configuration ? this.styleConf.configuration.name : 'default';
    }

  ngOnInit(): void {
    this.getDeviceActivities();

    this.refreshActivitiesSub = this.deviceService.refreshActivities$
      .subscribe(() => this.getDeviceActivities());
  }

  ngOnDestroy(): void {
      this.refreshActivitiesSub.unsubscribe();
  }

  public getDeviceActivities() {
    this.perPage = this.showMore ? 1000 : 6;
    this.deviceService.getDeviceActivities(this.currentUser.company_id, this.device.id, this.currentPage, this.perPage)
      .subscribe((activities: DeviceActivitiesModel[]) => {
        this.deviceActivities = activities
      });
  }

  public swapTicket(company_id, activity_id) {
    this.deviceService.swapTicket(company_id, activity_id)
    .subscribe((ticket) => {
      const dataToPrint = {
        ticket_id: ticket.ticket_number,
        employee: ticket.user_name,
        original_device_description: ticket.original_device_description,
        swap_device_description: ticket.swap_device_description,
        note: ticket.note
      };
      this.printService
        .printDocument('Swap Ticket', dataToPrint);
    });
  }

  getLatestAssignedActivity(deviceActivities: any[]): any | null {
    if (!deviceActivities || deviceActivities.length === 0) return null;
  
    const assignedActivities = deviceActivities.filter(activity => activity.device_status_id === 2);
  
    if (assignedActivities.length === 0) return null;

    return assignedActivities.reduce((latest, current) => {
      return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
    });
  }

  isDeviceReturned(activity, device_activities) {
    if (activity.activity_type === 11) {
      const deviceId = activity.device_id;

      const assignedActivity = device_activities.find(
        (act) => act.activity_type === 2 && act.device_id === deviceId && act.employee_id
      );

      if (assignedActivity) {
        const assignedDate = new Date(assignedActivity.activity_date);
        const currentActivityDate = new Date(activity.activity_date);
        return assignedDate < currentActivityDate;
      }
    }
    return false;
  }

  shouldShowPrintButton(deviceActivity: any, deviceActivities: any[]): boolean {
    if (!deviceActivity) return false;
  
    const { device_status_id, activity_type } = deviceActivity;

    
    const isExcludedActivity = [33, 34, 37].includes(activity_type);
    const isReturned = this.isDeviceReturned(deviceActivity, deviceActivities);
    const isValidStatus = device_status_id === 2 || (device_status_id === 1 && isReturned);

    return isValidStatus && !isExcludedActivity;
  }
  

  printAssigment(deviceActivity, device) {

    this.deviceReturned = deviceActivity?.activity_type === 11;
  
    if (this.deviceReturned) {
      this.latestAssignActivity = this.getLatestAssignedActivity(this.deviceActivities);
    }
    
    const data = {
      employee: {
        employee_name: {
          first_name: this.latestAssignActivity?.employee ? 
            this.latestAssignActivity?.employee?.first_name : 
            deviceActivity?.employee?.first_name,
          last_name: this.latestAssignActivity?.employee ? 
            this.latestAssignActivity?.employee?.last_name : 
            deviceActivity?.employee?.last_name
        },
        comment: deviceActivity?.comment
      },
      device,
      returned_device: this.deviceReturned
    }

    this.printService.printDocument('Assigment', data);
  }

}
