import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isNewDevice'
})

export class NewDevicePipe implements PipeTransform {
    transform(device: any): any {
        return device?.device_lifecycle_logs?.every(log => log?.device_status_id === 1);
    }
}