<div mat-dialog-content class="action-needed-dialog">
  <div class="lissa-modal">
    <div class="wrapper">
      <div class="header">
        <h1>{{ "MODALS.Delete Employee Confirmation" | translate }}</h1>
      </div>
      <div class="content">
        <form>
          <h2 class="title">{{ "MODALS.Losing Employee Data" | translate }}</h2>
        </form>
      </div>
      <div class="employeeInfo">
        <div class="employee-row" *ngFor="let employee of employees">
          <span class="name">{{ employee?.first_name }}</span>
          <span class="name">{{ employee?.last_name }}</span>
          <span class="email">{{ employee?.email }}</span>
          <span class="closeSpan">
            <button mat-icon-button class="closeButton" (click)="removeEmployee(employee)">
              <mat-icon>close</mat-icon>
            </button>
          </span>
        </div>
      </div>
      <div class="buttons">
        <button mat-dialog-close class="btn btn-secondary">
          {{ "MODALS.No" | translate | titlecase }}
        </button>
        <button (click)="confirm()" class="btn btn-primary" *ngIf="employees.length > 0"> 
          {{ "MODALS.Delete" | translate |  titlecase }}
        </button>
    </div>
  </div>
</div>
