import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nextMonth'
})
export class NextMonthPipe implements PipeTransform {

  transform(date: Date | string | null, format: string = 'DD.MM.YYYY'): string {
    if (!date) {
      return '';
    }

    const currentDate = new Date(date);
    if (isNaN(currentDate.getTime())) {
      return '';
    }

    // Move to the first day of the next month
    const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

    // Format the date as 'DD.MM.YYYY'
    const day = String(nextMonthDate.getDate()).padStart(2, '0');
    const month = String(nextMonthDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = nextMonthDate.getFullYear();

    if (format === 'DD.MM.YYYY') {
      return `${day}.${month}.${year}`;
    }

    // Additional formatting logic can be added here
    return nextMonthDate.toISOString();
  }

}
