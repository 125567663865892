import { throwError, Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { DeviceModel, EmployeeModel, ServiceCasesModel } from "../models";
import * as moment from "moment";

@Injectable()
export class DeviceService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private adminUrl = `${environment.assetUrl}/admin`;
  private authUrl = `${environment.assetUrl}`;
  private publicUrl = `${environment.assetUrl}/public`;
  public deviceUpdated: EventEmitter<any>;
  public selectedFilters = {
    employee_id: new EmployeeModel(),
    device: [],
  }
  private sortFilters = ['sort_added', 'sort_lifecycle', 'sort_employee_name'];

  private refreshActivities = new Subject<void>();
  refreshActivities$ = this.refreshActivities.asObservable();

  triggerActivityRefresh() {
    this.refreshActivities.next();
  }
 
  constructor(private router: Router, private http: HttpClient) {
    this.deviceUpdated = new EventEmitter();
  }

  getDeviceTypes(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/device/type`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getDeviceImage(path: string): Observable<any> {
    return this.http.get(`${this.publicUrl}${path}`, {responseType: 'text'})
    .pipe(catchError((error: any) => throwError(error)));
  }
  getBrandTypes(device_type_id: number): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/device/type/${device_type_id}/brand`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getArchiveReasons(): Observable<any>{
    return this.http.get(`${this.assetUrl}/config/archive/reasons`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getDeviceModels(brand_id: number, device_type_id: number): Observable<any> {
    return this.http
      .get(
        `${this.assetUrl}/device/brand/${brand_id}/model?device_type_id=${device_type_id}`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceSuppliers(): Observable<any> {
    return this.http
      .get(
        `${this.adminUrl}/config/device-supplier`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceVariants(
    brand_id: number,
    device_model_id: number
  ): Observable<any> {
    return this.http
      .get(
        `${this.assetUrl}/device/brand/${brand_id}/model/${device_model_id}/variant`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getMobileProviders(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/config/mobile-provider`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getInsurances(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/config/insurance`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCompanyInsurances(robotDevices?: boolean):Observable<any> {
    return this.http
    .get(`${this.assetUrl}/config/insurance/company${robotDevices ? '?robot_devices=' + robotDevices : ''}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getInsuranceProviders(): Observable<any> {
    return this.http
      .get(`${this.authUrl}/admin/config/insurance-supplier`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getDeviceStatuses(): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/device/status`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  createDevice(device: DeviceModel): Observable<any> {
    device.device_lifecycle.date_in = this.formatDate(
      device.device_lifecycle.date_in
    );
    device.device_lifecycle.date_start = this.formatDate(
      device.device_lifecycle.date_start
    );
    return this.http 
      .post(`${this.assetUrl}/company/${device.company_id}/device`, device)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDevices(
    company_id: number,
    branch_id: number,
    page: number,
    limit: number,
    filterObject: any,
    sort?: any,
    isDesc?: boolean,
  ): Observable<any> {

    let query = `?page=${page}&limit=${limit}`

    if (sort && this.sortFilters.includes(sort)) {
      query += `&${sort}=${isDesc}`;
    }
    
    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
      if (filterObject[k] && typeof filterObject[k] != 'object' && k !== 'device') {
          params = params.set(k, filterObject[k]);
      }
  });

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device${query}`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDevice(
    company_id: number,
    branch_id: number,
    device_id: number,
    lang?: string
  ): Observable<any> {
    let query = branch_id !== null ? `?branch_id=${branch_id}` : "";
    if (lang === 'de' ) {
      query = `?lang=${lang}`;
    }
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device/${device_id}${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  updateDevice(
    company_id: number,
    branch_id: number,
    device_id: number,
    device: DeviceModel
  ): Observable<any> {
    const query = branch_id !== null ? `?branch_id=${branch_id}` : "";
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/device/${device_id}${query}`,
        device
      )
      .pipe(catchError((error: any) => throwError(error)));
  }
  searchDevices(
    company_id: number,
    branch_id: number,
    serial_number: string,
    status: string
  ): Observable<any> {
    const query =
      branch_id !== null
        ? `?page=1&limit=10&branch_id=${branch_id}&serial_number=${serial_number}&status=${status}`
        : `?page=1&limit=10&serial_number=${serial_number}&status=${status}`;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  searchDevicesByImeiOrSerial(
    company_id: number,
    branch_id: number,
    value: string,
    device_status_id: any,
  ):Observable<any>{
    const query =
      branch_id !== null
        ? `&serial_imei=${value}&branch_id=${branch_id}&device_status=${device_status_id}`
        : `&serial_imei=${value}&device_status=${device_status_id}`
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/search-device-serial-imei?${query}`)
  }

  assignDevice(
    company_id: number,
    device_id: number,
    data: any
  ): Observable<any> {
    data.device_lifecycle.date_start = this.formatDate(data.device_lifecycle.date_start);
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/assign`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }
  assignDeviceFromRobot(
    company_id: number,
    device_id: number,
    data: any,
  ): Observable<any> {
    data.device_lifecycle.date_start = this.formatDate(data.device_lifecycle.date_start);
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/assign-from-locker`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }
  reassignToBranch(company_id: number, device_id: number, branch_id: number): Observable<any>{
    return this.http.post(`${this.assetUrl}/company/${company_id}/device/${device_id}/reassign-to-branch`, branch_id)
    .pipe(catchError((error: any) => throwError(error)));
  }
  backToPool(company_id: number, device_id: number): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/pool`,
        {}
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  doaDevice(company_id: number, device_id: number, data): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/doa`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  reAssign(company_id: number, device_id: number, employee_id: number, comment?: string): Observable<any> {
    return this.http
    .post(`${this.assetUrl}/company/${company_id}/device/${device_id}/reassign`, {employee_id, comment})
    .pipe(catchError((error: any) => throwError(error)));
  }

  serviceDevice(company_id: number, device_id: number, data: any): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/service`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  swapDevice(company_id: number, device_id: number, data: any): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/swap`,
        data
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  loanDevice(company_id: number, device_id: number): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/loan`,
        {}
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  getArchivedDevices(company_id: number, page?: number, limit?:number, filterObject?: {}): Observable<any> {
    const query = `page=${page}&limit=${limit}`;
    
    let params = new HttpParams();
    
    Object.keys(filterObject).forEach(k => {
      if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
      }
    });

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/archived/device?device_status_id=8&${query}`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  archiveDevice(company_id: number, device_id: number, payload?: {}): Observable<any> {
    return this.http
      .post(
        `${this.assetUrl}/company/${company_id}/device/${device_id}/archive`,
        payload
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateArchivedDevice(company_id: number, device_id: number, payload: {}): Observable<any> {
    return this.http.patch(`${this.assetUrl}/company/${company_id}/archived/device/${device_id}`, payload)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getArchivedFile(company_id: number, device_id: number): Observable<any> {
    return this.http.get(`${this.assetUrl}/company/${company_id}/archived/device/${device_id}/file`)
    .pipe(catchError((error: any) => throwError(error)))
  }

  checkArchivedFile(company_id: number, device_id: number): Observable<any> {
    return this.http.get(`${this.assetUrl}/company/${company_id}/archived/device/${device_id}/show-file`)
    .pipe(catchError((error: any) => throwError(error)))
  }

  getBrands(): Observable<any> {
    return  this.http.get(`${this.authUrl}/admin/device/brand`).pipe(
        catchError((error: any) => throwError(error)));
  }
  getModels(): Observable<any> {
    return this.http.get(`${this.authUrl}/admin/device/model`)
    .pipe(catchError ((error: any) => throwError(error)));
  }
  swapTicket(company_id: number, activity_id: number): Observable<any> {
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/swap-ticket?activity_id=${activity_id}`)
    .pipe(catchError ((error: any) => throwError(error)));
  }

  public formatDate(date): string {
    if (date) return moment(date).format("YYYY-MM-DD");
    return date;
  }
  // resolveAction(company_id: number, device_id: number, action_needed_id: number): Observable<any> {
  //   return this.http
  //   .post(`${this.assetUrl}/company/${company_id}/device/${device_id}/action-needed/resolve-swap?action_needed_id=${action_needed_id}`, {})
  //   .pipe(catchError((error: any) => throwError(error)));
  // }
  resolveAction(company_id: number, device_id: number, service_case: ServiceCasesModel): Observable<any> {
    return this.http
    .post(`${this.assetUrl}/company/${company_id}/device/${device_id}/action-needed/resolve-service-case`, service_case)
    .pipe(catchError((error: any) => throwError(error)));
  }

  resolveActionManually(company_id: number, device_id: number, actionID: number, actionInfo: {}):Observable<any> {
      return this.http.post(`${this.assetUrl}/company/${company_id}/device/${device_id}/action-needed/manually-resolve?action_needed_id=${actionID}`, actionInfo)
      .pipe(catchError((error: any) => throwError(error)));
  }


  exportDevicesAsPDF(company_id: number, filterObject: any): Observable<any> {

    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
            params = params.set(k, filterObject[k]);
        }
    });
    return this.http
    .get(`${this.assetUrl}/company/${company_id}/device/export`, {params})
    .pipe(catchError((error: any) => throwError(error)));
  }

  getDeviceActivities(companyID: number, deviceID: number, page?: number, limit?: number) {
    let query = `?page=${page}&limit=${limit}`;
    return this.http.get(`${this.assetUrl}/company/${companyID}/device/${deviceID}/activities${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getServiceStepPDFOffer(company_id: number, device_id: number, service_case_id: number, document_type: string): Observable<any>{
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/${device_id}/service-case/${service_case_id}/pdf?document_type=${document_type}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  cancelServiceStep(company_id: number, device_id: number, service_case_id: number): Observable<any>{
    return this.http.post(`${this.assetUrl}/company/${company_id}/device/${device_id}/service-case/${service_case_id}/cancel`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }
 
}
