import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Enums } from 'src/app/shared/enums/lists.enum';
import { AreYouSureModalComponent } from "../are-you-sure-modal/are-you-sure-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-extend-lifecycle-modal",
  templateUrl: "./extend-lifecycle-modal.component.html",
  styleUrls: ["./extend-lifecycle-modal.component.scss"],
})
export class ExtendLifecycleModalComponent implements OnInit {
  dialogConfig = new MatDialogConfig();
  lifecycleForm: FormGroup;
  customText: string;
  lifecycleStartDate: string;
  extensionPeriods: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExtendLifecycleModalComponent>,
    private enums: Enums,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.customText = data.text;
    this.extensionPeriods = enums.lifecycleExtensionPeriod;
  }

  ngOnInit(): void {
    this.lifecycleForm = this.fb.group({
      extend_date: [{ value: "", disabled: false }, Validators.required],
      duration: [this.enums.lifecycleExtensionPeriod.CUSTOM, Validators.required],
    });

    this.lifecycleForm.get("duration").valueChanges.subscribe((value) => {
      const dateControl = this.lifecycleForm.get("extend_date");

      if (value === this.enums.lifecycleExtensionPeriod.CUSTOM) {
        return;
      }
      const months = parseInt(value);
      const newDate = moment().add(months, "months").toDate();
      dateControl.setValue(newDate);
    });
  }

  dateFilter = (date: Date) => {
    return (
      moment(date).format("YYYY-MM-DD") >= moment(moment()).format("YYYY-MM-DD")
    );
  };

  confirm() {
    if (this.lifecycleForm.valid) {
      const extensionDate = this.lifecycleForm.get("extend_date").value;
      const formattedDate = moment(extensionDate).format('DD/MM/YYYY');

      this.dialogConfig.data = {
        title: this.translate.instant("MODALS.Are you sure?"),
        message: this.translate.instant("DEVICEINFO.Confirm lifecycle extension", {date: formattedDate}),
        confirmText: this.translate.instant("MODALS.COMFIRM"),
      };

      this.dialogConfig.autoFocus = true;

      const confirmRef = this.dialog.open(
        AreYouSureModalComponent,
        this.dialogConfig
      );

      confirmRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close({extend_date: extensionDate});
        }
      });
    }
  }
}
