import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-delete-employee-modal",
    templateUrl: "./delete-employee-modal.component.html",
    styleUrls: ["./delete-employee-modal.component.scss"]
})

export class DeleteEmployeeModalComponent implements OnInit {
    employees: Array<{
        id: number;
        first_name: string;
        last_name: string;
        email: string;
    }> = [];
    
    constructor(
      @Inject(MAT_DIALOG_DATA) data,
      private dialogRef: MatDialogRef<DeleteEmployeeModalComponent>,
    ) {
        this.employees = Array.isArray(data.deletingList) && data.deletingList;
    }
  
    ngOnInit() {}

    public removeEmployee(employee) {
        const index = this.employees.findIndex(emp => emp.email === employee.email);
        if (index !== -1) {
            this.employees.splice(index, 1);
        }

        if (this.employees.length === 0) {
            this.dialogRef.close();
        }
    }

    confirm() {
        const employeeIds = this.employees.map(emp => emp.id);
        this.dialogRef.close(employeeIds);
    }
  }
