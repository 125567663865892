<div class="flex-column">
    <div class="col-12 col-md-12 px-0">
        <div class="change-status-border pb-0 p-3">
            <div class="nav">
                <h2 class="center nav-status-title m-0">
                    {{customText}}
                </h2>
                <span mat-dialog-close class="x-button"><img src="../../../../assets/icons/x-button.png"></span>
            </div>
        </div>
        <div class="divider my-0 mx-3"></div>
    </div>
</div>

<div class="space-line"></div>
<form class="wizard-big wizard clearfix mx-3" role="form" [formGroup]="lifecycleForm" novalidate>
    <mat-dialog-content class="flex-column content">
                <div class="col-12 col-md-12 px-0">
                    <p class="extend-text">{{'DEVICEINFO.Extend text' | translate}}</p>
                    <div class="radio-button-container col-12 col-md-12 px-0">
                        <mat-radio-group [matTooltip]="'DEVICEINFO.Lifecycle extension tooltip' | translate" matTooltipPosition="right" formControlName="duration" class="full-width">
                            <div class="radio-options-container d-flex flex-column">
                                <mat-radio-button [value]="this.extensionPeriods.CUSTOM">{{'DEVICEINFO.Custom' | translate}}</mat-radio-button>
                                <mat-radio-button [value]="this.extensionPeriods.MONTHS_3">{{'DEVICEINFO.3 months' | translate}}</mat-radio-button>
                                <mat-radio-button [value]="this.extensionPeriods.MONTHS_6">{{'DEVICEINFO.6 months' | translate}}</mat-radio-button>
                                <mat-radio-button [value]="this.extensionPeriods.MONTHS_12">{{'DEVICEINFO.12 months' | translate}}</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <mat-form-field color="accent" class="datePicker-container full-width" appearance="outline">
                        <input
                            required 
                            matInput 
                            placeholder="{{'DEVICEINFO.Extend date' | translate}}"
                            [matDatepicker]="picker1" 
                            [matDatepickerFilter]="dateFilter"
                            formControlName="extend_date"
                            [disabled]="lifecycleForm.get('duration').value !== this.extensionPeriods.CUSTOM">
                        <mat-datepicker-toggle matSuffix [for]="picker1">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
    </mat-dialog-content>
    <mat-dialog-actions class="center-buttons">
        <button mat-dialog-close class="btn cancel-btn mr-3 ml-auto">{{'ASSIGNDEVICE.Cancel' | translate | titlecase}}</button>
        <button type="submit" [disabled]="!lifecycleForm.valid" class="btn btn-primary" (click)="confirm()">{{'DEVICEINFO.Complete' | translate | titlecase}}</button>
    </mat-dialog-actions>
</form>